
import { defineComponent } from "@vue/runtime-core";
import AppPage from "@/components/AppPage.vue";
import Button from "@/components/Button.vue";
import DataTable from "@/components/DataTable.vue";
import { Pagination, Sorting } from "@/use/types";
import TextInput from "@/components/TextInput.vue";
import Tooltip from "@/components/Tooltip.vue";
import Editor from "./Editor.vue";
import RemoveAdvertiserPopup from "./RemoveAdvertiserPopup.vue";

import moment from "moment-timezone";
import axios from "axios";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  totalRows: number;
  columns: Column[];
  definition: any;
  actionsVisibleIndex: number | null;
  isRemovedPopupShowed: boolean;
  removedAdvertiserId: number | null;
  advertiserToEdit: null;
}

interface IQuery {
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

export function getDefaultQuery(): IQuery {
  return {
    search: "",
    sorting: { column: "name", direction: "asc" },
    pagination: { limit: 10, offset: 0 },
  };
}

const dataColumns = [
  {
    id: "name",
    name: "РЕКЛАМОДАТЕЛЬ",
    selected: true,
    sortable: true,
    freezed: true,
    type: "text",
    searchable: true,
  },
  {
    id: "url_template",
    name: "Параметры постбэка",
    selected: true,
    sortable: true,
  },
  {
    id: "url_site",
    name: "Сайт",
    selected: true,
    sortable: false,
  },
  {
    id: "token",
    name: "Токен",
    selected: true,
    sortable: false,
  },
  {
    id: "buttons",
    name: "",
    selected: true,
    freezed: true,
    sortable: false,
    htmlClass: "advertiser__table--action",
  },
];

export default defineComponent({
  components: {
    AppPage,
    Button,
    DataTable,
    TextInput,
    Tooltip,
    Editor,
    RemoveAdvertiserPopup,
  },
  data(): IDataModel {
    return {
      loading: false,
      filter: getDefaultQuery(),
      rows: [],
      totalRows: 0,
      columns: dataColumns,
      definition: {
        shortcut: null,
        timezone: "Europe/Kiev",
      },
      actionsVisibleIndex: null,
      isRemovedPopupShowed: false,
      advertiserToEdit: null,
      removedAdvertiserId: null,
    };
  },
  created() {
    this.load();
  },
  mounted: function () {
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
  },
  methods: {
    copyLink(url: string) {
      navigator.clipboard.writeText(url);
    },
    setActionsVisible(id: number | null) {
      if (this.actionsVisibleIndex === id) {
        this.actionsVisibleIndex = null;
      } else {
        this.actionsVisibleIndex = id;
      }
    },
    removeAdvertiser(id: number) {
      this.isRemovedPopupShowed = true;
      this.removedAdvertiserId = id;
      this.actionsVisibleIndex = null;
    },
    closePopup() {
      if (this.isRemovedPopupShowed) {
        this.isRemovedPopupShowed = false;
        this.removedAdvertiserId = null;
      }

      if (this.advertiserToEdit) {
        this.advertiserToEdit = null;
        document.querySelector("body")?.classList.remove("editor-opened");
      }
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    clear() {
      this.filter = getDefaultQuery();
      this.load();
    },
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;

      try {
        const { data } = await axios.get("/api/advertisers", {
          params: { q: JSON.stringify(this.filter) },
        });
        this.rows = data.rows;
        this.totalRows = data.total;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
    download() {
      window.open(
        `/api/advertisers/download?q=${encodeURIComponent(
          JSON.stringify(this.filter)
        )}`,
        "_blank"
      );
    },
    openEditor(row: any) {
      document.querySelector("body")?.classList.add("editor-opened");

      this.advertiserToEdit = row || {
        name: "",
        url_template: "",
        url_site: "",
      };

      if (this.actionsVisibleIndex !== null) this.actionsVisibleIndex = null;
    },
  },
});

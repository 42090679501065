
import { defineComponent } from "@vue/runtime-core";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import Button from "@/components/Button.vue";

import axios from "axios";

export default defineComponent({
  components: {
    Popup,
    TextInput,
    Button,
  },
  props: {
    advertiser: Object,
  },
  data() {
    return {
      errors: {} as { [key: string]: string | null },
      options: {
        advertisers: [],
      },
    };
  },
  emits: ["close", "update:advertiser", "saved"],
  methods: {
    close() {
      this.$emit("close");
    },
    emitPatch(patch: any) {
      this.$emit("update:advertiser", {
        ...this.advertiser,
        ...patch,
      });

      for (const k of Object.keys(patch)) {
        this.errors[k] = null;
      }
    },
    async saveAdvertiser() {
      try {
        await axios.post("/api/advertisers", this.advertiser);
        this.$emit("saved");
        this.$emit("close");
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
});
